.Intro{
    height: 100%;
    width: 100%;
    padding: 0px 80px;
}
.inside-intro-container{
    height: 90%;
    width: 100%;
}
.video-intro{
    height: 90%;
    width: 100%;
    object-fit: contain;
}

@media screen and (max-width:850px) {
    .Intro{
        height: 100%;
        width: 100%;
        padding: 0px 50px;
    }
}

@media screen and (max-width:450px) {
    .Intro{
        height: 100%;
        width: 100%;
        padding: 0px 0px;
    }
}