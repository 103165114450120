.pagination-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}
.page-item{
    list-style: none;
    margin: 0px 10px;
    width: 40px;
    height: 40px;
    padding: 10px;
    color: black;
    font-size: 16px;
    text-align: center;
}
.active-item{
    background: rgba(128, 128, 128, 0.226);
    border-radius: 50%;
}