.description{
    margin: 15px 0px;
    text-align: justify;
    padding: 5px 0px;
    font-size: 20px;
}
.description b i{
    font-size: 30px;
}

@media screen and (max-width:500px) {
    .description{
        font-size: 16px;
        text-align: left;
    }
}