.loading{
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background: #F4F4F4;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    flex-direction: column;
}
.alt-loading{
    position: fixed;
    display: none;
}

.loading-company-logo{
    height: 10vw;
    width: 60vw;
    margin-bottom: 40px;
}
.loading-company-logo-image{
    height: 100%;
    width: 100%;
}

  
  /* body {
    background-color: #ff8484ff;
    height: 100vh;
    display: grid;
    place-items: center;
    position: relative;
  } */
  
  .scene {
    position: relative;
    z-index: 2;
    height: 120px;
    width: 120px;
    display: grid;
    place-items: center;
  }
  
  .cube-wrapper {
    transform-style: preserve-3d;
    animation: bouncing 2s infinite;
  }
  
  .cube {
    transform-style: preserve-3d;
    transform: rotateX(45deg) rotateZ(45deg);
    animation: rotation 2s infinite;
  }
  
  .cube-faces {
    transform-style: preserve-3d;
    height: 40px;
    width: 40px;
    position: relative;
    transform-origin: 0 0;
    transform: translateX(0) translateY(0) translateZ(-20px);
  }
  
  .cube-face {
    position: absolute;
    inset: 0;
    background: #110d31ff;
    border: solid 1px #ff8484ff;
  }
  .cube-face.shadow {
    transform: translateZ(-40px);
    animation: bouncing-shadow 2s infinite;
  }
  .cube-face.top {
    transform: translateZ(40px);
  }
  .cube-face.front {
    transform-origin: 0 50%;
    transform: rotateY(-90deg);
  }
  .cube-face.back {
    transform-origin: 0 50%;
    transform: rotateY(-90deg) translateZ(-40px);
  }
  .cube-face.right {
    transform-origin: 50% 0;
    transform: rotateX(-90deg) translateY(-40px);
  }
  .cube-face.left {
    transform-origin: 50% 0;
    transform: rotateX(-90deg) translateY(-40px) translateZ(40px);
  }
  
  @keyframes rotation {
    0% {
      transform: rotateX(45deg) rotateY(0) rotateZ(45deg);
      animation-timing-function: cubic-bezier(0.17, 0.84, 0.44, 1);
    }
    50% {
      transform: rotateX(45deg) rotateY(0) rotateZ(225deg);
      animation-timing-function: cubic-bezier(0.76, 0.05, 0.86, 0.06);
    }
    100% {
      transform: rotateX(45deg) rotateY(0) rotateZ(405deg);
      animation-timing-function: cubic-bezier(0.17, 0.84, 0.44, 1);
    }
  }
  @keyframes bouncing {
    0% {
      transform: translateY(-20px);
      animation-timing-function: cubic-bezier(0.76, 0.05, 0.86, 0.06);
    }
    45% {
      transform: translateY(20px);
      animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    }
    100% {
      transform: translateY(-20px);
      animation-timing-function: cubic-bezier(0.76, 0.05, 0.86, 0.06);
    }
  }
  @keyframes bouncing-shadow {
    0% {
      transform: translateZ(-80px) scale(1.3);
      animation-timing-function: cubic-bezier(0.76, 0.05, 0.86, 0.06);
      opacity: 0.05;
    }
    45% {
      transform: translateZ(0);
      animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
      opacity: 0.3;
    }
    100% {
      transform: translateZ(-80px) scale(1.3);
      animation-timing-function: cubic-bezier(0.76, 0.05, 0.86, 0.06);
      opacity: 0.05;
    }
  }

  @media screen and (max-width:600px) {
    .loading-company-logo {
        height: 15vw;
        width: 90vw;
    }
  }