#AboutUs {
  height: 85vh;
  width: 100%;
  margin: 30px 0px;
  padding: 40px 0px;
  overflow: hidden;
}

.aboutus-inside-container {
  padding: 0px 80px;
  display: flex;
  height: 100%;
}

.aboutus-left-component {
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.aboutus-left-heading {
  width: 100%;
  margin-bottom: 30px;
}

.aboutus-left-heading-content {
  /* font-size: 3.1em; */
  font-size: 3.3vw;
  font-weight: 900;
  background: -webkit-linear-gradient(
    27deg,
    rgba(34, 86, 195, 1) 0%,
    rgba(251, 0, 20, 1) 45%,
    rgba(253, 187, 45, 1) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.aboutus-left-description {
  width: 100%;
  margin-bottom: 30px;
  font-size: 18px;
  text-align: justify;
}

.alternate-aboutus-left-component{
    display: none;
}

@media screen and (min-width:1500px){
  #AboutUs{
    height: 80vh;
  }
  .aboutus-left-heading-content{
    font-size: 2.8vw;
  }
  .aboutus-left-description{
    font-size: 1.3vw;
  }
}


@media screen and (max-width: 1300px) {
  .aboutus-left-heading-content {
    /* font-size: 2.5em; */
    font-size: 3.3vw;
  }
}

@media screen and (max-width: 1024px) {
  .aboutus-left-description {
    font-size: 16px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 850px) {
  .aboutus-left-component {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .aboutus-left-heading-content {
    font-size: 1.8em;
    /* font-size: 3.3vw; */
  }
  .aboutus-inside-container {
    padding: 0px 50px;
  }
  .aboutus-left-description {
    font-size: 14px;
    margin-bottom: 20px;
    text-align: justify;
  }
}

@media screen and (max-width: 600px) {
  .aboutus-left-component {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media screen and (max-width: 500px) {
  .aboutus-left-heading-content {
    font-size: 1.5em;
    display: none;
  }
  .aboutus-left-description {
    font-size: 14px;
    margin-bottom: 20px;
    text-align: justify;
  }
  .alternate-aboutus-left-component{
    font-size: 1.7em;
    display: block;
    font-weight: 900;
    background: -webkit-linear-gradient(
      27deg,
      rgba(34, 86, 195, 1) 0%,
      rgba(251, 0, 20, 1) 45%,
      rgba(253, 187, 45, 1) 100%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
@media screen and (max-width: 470px) {
  #AboutUs{
    height: 90vh;
    margin: 60px 0px;
  }
  .aboutus-inside-container {
    padding: 0px 20px;
  }
  .aboutus-left-description {
    margin-bottom: 10px;
  }
  .aboutus-left-heading{
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 370px) {
  #AboutUs{
    height: 80vh;
    margin: 60px 0px;
  }
  .aboutus-inside-container {
    padding: 0px 20px;
  }
  .aboutus-left-description {
    margin-bottom: 10px;
  }
  .aboutus-left-heading{
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 320px) {
  #AboutUs{
    height: 90vh;
    margin: 70px 0px;
    padding: 0px 0px;
  }
  .aboutus-inside-container {
    padding: 0px 20px;
  }
  .aboutus-left-description {
    margin-bottom: 10px;
  }
  .aboutus-left-heading{
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 280px) {
  #AboutUs{
    height: 83vh;
  }
}
