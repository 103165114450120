.Company_Carousel {
  flex: 1 !important;
  height: inherit;
}
.logoCarouselContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 2;
  height: 100%;
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0,212,255,0) 100%),url("../../../images/solutions/sec02-block-07@3x.png");
  background-repeat: no-repeat;
  object-fit: fill;
  border-radius: 30px;
  /* border: 1px solid rgba(0, 0, 0, 0.223); */
  overflow: hidden !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
#logoCarousel{
  width: 100% !important;
  height: 50% !important;
  overflow: hidden !important;
  margin-bottom: 10px !important;
}
#workNameCarousel{
  width: 100% !important;
  height: 50% !important;
  overflow: hidden !important;
  background: white !important;
  text-align: center !important;
}
.carousel-caption-logo-name h5{
  position: absolute;
  text-align: center !important;
  width: 100%;
  top: 40%;
  left: 10px;
  font-size: 55px !important;
  font-weight: 600;
  background: -webkit-linear-gradient(27deg, rgba(34,86,195,1) 0%, rgba(251,0,20,1) 20%, rgba(253,187,45,1) 70%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* margin-bottom: 30px; */
  text-align: left;
}
.carousel-caption-work-name h5{
  position: absolute;
  text-align: center !important;
  width: 100%;
  top: 40% !important;
  left: 10px;
  font-size: 55px !important;
  font-weight: 600;
  background: -webkit-linear-gradient(27deg, rgba(34,86,195,1) 0%, rgba(251,0,20,1) 20%, rgba(253,187,45,1) 70%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* margin-bottom: 30px; */
  text-align: left;
}



.inner-company-carousel {
  height: 100%;
  width: 100%;
}
.company-name-image-container {
  /* height: 90vh; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background: rgb(219, 219, 219);
}


#workNameCarouselAlternate{
  display: none !important;
}

@media screen and (max-width: 1500px) {
  .carousel-caption-work-name h5{
    font-size: 35px !important;
    left: 0px;
  }
  .carousel-caption-logo-name h5{
    font-size: 35px !important;
    left: 0px;
  }
}

@media screen and (max-width: 1200px) {
  .carousel-caption-work-name h5{
    font-size: 25px !important;
  }
  .carousel-caption-logo-name h5{
    font-size: 25px !important;
  }
}

/* @media screen and (max-width: 900px) {
  .logoCarouselContainer{
    height: 40% !important;
    flex: none ;
  }
  #logoCarousel {
    /* flex: none; */
    /* height: 50% !important;
  } */
  /* #workNameCarouse{ */
    /* height: 50% !important;
  } */
/* } */ 
/* @media screen and (max-width: 850px) {
  .logoCarouselContainer{
    height: 30% !important;
  }
} */


/* @media screen and (max-width:450px) {
  .carousel-caption-logo-name h5{

    top: 30%;

  }
  .carousel-caption-work-name h5{
    font-size: 1.3rem !important;
    top: 30%;
  }
} */

/* @media screen and (max-width:450px) {
  .logoCarouselContainer{
    height: 50% !important;
    flex: none ;
  }
  .carousel-caption-logo-name h5{
    top: 35%;
    font-size: 24px !important;
  }
  .carousel-caption-work-name h5{
    font-size: 1rem !important;
    top: 35%;
  }
} */

/* @media screen and (max-width:370px) {
  .logoCarouselContainer{
    height: 40% !important;
    flex: none ;
  }
} */
