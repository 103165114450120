*{
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  font-family: 'Outfit', sans-serif;
  scroll-behavior: smooth;
}
body{
  background: rgba(208, 208, 208, 0.243);
}
a{
  /* cursor: pointer; */
}

@media screen and (min-width:1500px) {
  /* body{
    width: 100%;
    padding: 0rem 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    
  }
  .App{
    width: 1540px;
  } */
}

@media screen and (min-width:2000px) {
  body{
    width: 100%;
    padding: 0rem 20rem;
    display: flex;
    align-items: center;
    justify-content: center;
    
  }
  .App{
    width: 1840px;
  }
}
/* @media screen and (min-width:2000px) {
  body{
    width: 100%;
    padding: 0rem 20rem;
  }
} */