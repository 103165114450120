.Posts {
  margin: 40px 0px;
  padding: 0px 80px;
}

.insidePostsContainer {
  display: flex;
  flex-direction: column;
}

.posts-return-insight-button {
  color: black;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: clamp(0.7rem, 0.5774rem + 2.4936vw, 2.826rem);
  margin-bottom: 20px;
}

.posts-return-insight-button a {
  color: black;
  text-decoration: none;
}
.insight-back-arrow-posts {
  height: inherit !important;
  width: clamp(0.7rem, 0.5774rem + 2.4936vw, 2.026rem) !important;
}

.posts-tree-container {
  color: rgba(0, 0, 0, 0.637);
  margin: 10px 0px;
}
.posts-tree-container a {
  text-decoration: none;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.637);
  margin-right: 0px 5px;
}
.posts-tree-container a:hover {
  font-weight: 500;
  color: black;
}

.posts-title-container {
  font-size: clamp(2.5rem, 1.0774rem + 3.7936vw, 5.626rem);
  font-weight: 500;
}

.posts-tags-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 10px;
}
.posts-tag {
  display: flex;
  font-size: 15px;
  color: #6f5deb;
  margin: 5px 0px;
  margin-right: 10px;
  font-weight: 500;
  border: 1px solid #6f5deb;
  border-radius: 10px;
  padding: 8px;
  cursor: pointer;
  flex-wrap: wrap;
}

.posts-image-container {
  height: 50vw;
  width: 100%;
  margin-top: 20px;
}

.posts-image {
  height: 100%;
  object-fit: cover;
  width: 100%;
  border-radius: 30px;
}

.posts-description-container {
  width: 100%;
  margin-top: 20px;
  user-select: none;
}

.posts-suggestion-container {
  margin: 20px 0px;
}

.download_pdf_container {
  margin: 30px 0px;
  padding: 10px 0px;
}
.download-pdf-button {
  width: max-content;
  font-size: 20px;
  padding: 20px;
  border: 1px solid black;
  border-radius: 10px;
  cursor: pointer;
}
.download-pdf-button:hover {
  background: -webkit-linear-gradient(
    27deg,
    rgba(34, 86, 195, 1) 0%,
    rgba(251, 0, 20, 1) 45%,
    rgba(253, 187, 45, 1) 100%
  );
  color: white;
  border: none;
}

.post-form-container {
  margin: 40px 0px;
}

.post-form-header1-container {
  font-size: clamp(2.5rem, 1.0774rem + 2.7936vw, 5.626rem);
  font-weight: 500;
}
.post-form-header2-container {
  font-size: clamp(2.5rem, 1.0774rem + 2.7936vw, 5.626rem);
  font-weight: 500;
  margin-bottom: 10px;
}

.post-contactus-form {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 10px;
}

.post-input-container {
  position: relative;
  display: flex;
  align-items: center;
  background: rgb(234, 234, 234);
  border-radius: 30px;
  padding: 0px 20px;
}

.contactus-input-fields-posts {
  background: none;
  border: none;
  padding: 15px;
  width: 100%;
  margin: 2px 0px;
  background: none;
  -webkit-appearance: none;
  -moz-appearance: textfield;
  appearance: none;
}

.contactus-input-fields-posts:focus {
  outline: none;
}

.contactus-button-posts {
  padding: 15px;
  border-radius: 30px;
  border: 1px solid black;
}

.contactus-button-posts:hover {
  background: -webkit-linear-gradient(
    27deg,
    rgba(34, 86, 195, 1) 0%,
    rgba(251, 0, 20, 1) 45%,
    rgba(253, 187, 45, 1) 100%
  );
  color: white;
  border: none;
}

@media screen and (max-width: 1150px) {
    .post-contactus-form {
        display: grid;
        grid-template-columns: auto auto auto;
        grid-template-rows: auto auto;
        gap: 10px;
      }
}

@media screen and (max-width: 850px) {
  .Posts {
    padding: 0px 50px;
  }
  .post-contactus-form {
    display: grid;
    grid-template-columns: none;
    grid-template-rows: auto auto auto auto;
    gap: 10px;
  }
}

@media screen and (max-width: 470px) {
  .Posts {
    padding: 0px 20px;
  }
  .posts-tree-container {
    font-size: 14px;
  }
  .posts-tag {
    font-size: 12px;
    padding: 5px;
  }
}
