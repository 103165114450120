.Insight {
  margin: 40px 0px;
  padding: 0px 80px;
  display: flex;
  flex-direction: column;
}

.insight-title {
  font-size: clamp(2.5rem, 1.0774rem + 3.7936vw, 5.626rem);
  font-weight: 500;
}

.insight-top-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 30px;
  position: relative;
}
.insight-top-container-title {
  font-size: clamp(1.3rem, 0.7774rem + 2.5936vw, 3.026rem);
  font-weight: 400;
  position: absolute;
  background: #f4f4f4;
  color: black;
  z-index: 1;
  top: -1px;
  border-bottom-right-radius: 30px;
}
.insight-top-container-title::before {
  position: absolute;
  background: transparent;
  height: 60px;
  width: 60px;
  z-index: -1;
  bottom: -60px;
  border-radius: 30px;
  box-shadow: -28px -28px 0 0 #f4f4f4;
  left: 0px;
  content: "";
}
.insight-top-container-title::after {
  position: absolute;
  background: transparent;
  height: 60px;
  width: 60px;
  z-index: -1;
  top: 0px;
  border-radius: 30px;
  box-shadow: -28px -28px 0 0 #f4f4f4;
  right: -60px;
  content: "";
}

.insight-top-container-new-insight-container {
  width: 100%;
  position: relative;
  cursor: pointer;
}
.insight-top-container-new-insight-image-container {
  height: 100%;
  width: 100%;
  display: inline-block;
  border-radius: 30px;
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.65) 100%
  ); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgba(0, 0, 0, 0.65)),
    color-stop(100%, rgba(0, 0, 0, 0))
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.65) 100%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.65) 100%
  ); /* Opera 11.10+ */
  background: -ms-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.65) 100%
  ); /* IE10+ */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.65) 100%
  ); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
}

.insight-top-container-new-insight-title {
  position: absolute;
  z-index: 1;
  font-size: clamp(0.7rem, 0.5774rem + 2.4936vw, 2.826rem);
  font-weight: 400;
  left: -1px;
  bottom: -1px;
  /* background: #F4F4F4;
    border:1px solid #F4F4F4; */
  color: rgb(255, 255, 255);
  padding: 10px;
  left: 10px;
  bottom: 10px;
  border-radius: 30px;
  /* border-top-right-radius: 30px; */
  text-align: center;
}
/* .insight-top-container-new-insight-title::before{
    position: absolute;
    background: transparent;
    height: 60px;
    width: 60px;
    z-index: -1;
    top: -61px;
    border-radius: 30px;
    box-shadow: -28px 28px 0 0 #F4F4F4;
    left: 0px; */
/* background: #F4F4F4; */
/* content: "";
}
.insight-top-container-new-insight-title::after{
    position: absolute;
    background: transparent;
    height: 60px;
    width: 60px;
    z-index: -1;
    bottom: 0px;
    border-radius: 30px;
    box-shadow: -28px 28px 0 0 #F4F4F4;
    right: -61px; */
/* background: #F4F4F4; */
/* content: "";
} */
.new-insight-title-arrow {
  font-size: 2rem !important;
  margin-left: 20px;
  transition: transform 0.9s ease-in-out;
}
.insight-top-container-new-insight-container:hover .new-insight-title-arrow {
  transform: translateX(3px) translateY(-3px);
}
.insight-top-container-new-insight-image {
  /* background: linear-gradient(to bottom, rgba(245, 246, 252, 0), rgba(0, 0, 0, 0.73)); */
  height: 35vw;
  object-fit: cover;
  width: 100%;
  z-index: -1;
  position: relative;
  border-radius: 30px;
}

.insight-middle-container {
  display: flex;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  align-items: center;
  justify-content: center;
}
::-webkit-scrollbar {
  display: none;
}

.inside-insight-middle-container {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  /* -ms-overflow-style: none;
    scrollbar-width: none; */
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0px;
  transition: all 0.7s ease;
  z-index: 1;
}
.tags {
  height: 24px;
  width: auto;
  margin: 0px 4px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 18px;
  background: #ffffff;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  /* font-size: 1vw; */
  font-size: clamp(0.5rem, 0.4774rem + 2.0936vw, 1.126rem);
  cursor: pointer;
  z-index: 2;
  border: 1px solid black;
}
.active-tag {
  background-color: black;
  color: white;
}
.inner-arrow {
  position: absolute;
  width: 5%;
  display: none;
  align-items: center;
  position: absolute;
  top: 5;
  height: 80%;
  z-index: 3;
  cursor: pointer;
}
.inner-arrow.arrow-active {
  display: flex;
}
.left-arrow {
  left: 0;
  height: 100%;
  color: rgb(0, 0, 0);
  background: linear-gradient(to right, #f3f3f3 5%, transparent);
}
.right-arrow {
  right: 0;
  height: 100%;
  color: rgb(0, 0, 0);
  background: linear-gradient(to left, #f3f3f3 5%, transparent);
}

.inside-bottom-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
}
.inside-insight-bottom-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
}

.insights-pagination-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0px;
}
.bottom-container-row{
  width: 100%;
  height: 100%;
}

.card-button {
  border: none;
  padding: 10px;
  color: #445deb;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #4e4e4e;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  /* background: rgb(131,58,180);
background: linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%);
color: white;
} */
  color: white;
}
.card-button-arrow {
  margin-left: 4px;
}
.card-button:hover {
  /* outline: 1px solid #445deb; */
}
.card-button:hover .card-button-arrow {
  transition: transform 0.3s ease-in-out;
  transform: translate(3px, -3px);
}


.skeleton-box {
  display: inline-block;
  height: 20vw;
  position: relative;
  overflow: hidden;
  background-color: #a1a1a1;
}
.skeleton-box::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0.5) 60%,
    rgba(255, 255, 255, 0)
  );
  -webkit-animation: shimmer 10s infinite;
  animation: shimmer 10s infinite;
  content: "";
}
@-webkit-keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.card-img-top{
  height: 20vw;
  width: 100%;
}

@media screen and (min-width: 2000px) {
  .inside-insight-middle-container {
    overflow-x: hidden;
    flex-wrap: wrap;
    justify-content: left;
  }
  .tags {
    margin: 10px 10px;
  }
  .card-img-top{
    height: 16  vw;
    width: 100%;
  }
}

@media screen and (max-width: 1750px) {
  .inside-insight-middle-container {
    overflow-x: hidden;
    flex-wrap: wrap;
    justify-content: left;
  }
  .tags {
    margin: 10px 10px;
  }
}

@media screen and (max-width: 1100px) {
  .inside-insight-middle-container {
    overflow-x: hidden;
    flex-wrap: wrap;
    justify-content: left;
  }
  .tags {
    margin: 5px 5px;
  }
}

@media screen and (max-width: 850px) {
  .Insight {
    margin: 40px 0px;
    padding: 0px 50px;
    display: flex;
    flex-direction: column;
  }
  .inside-insight-middle-container {
    overflow-x: scroll;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  .inner-arrow {
    display: block;
  }
  .tags {
    margin: 0px 4px;
  }
  .insight-top-container-title::after {
    position: absolute;
    background: transparent;
    height: 60px;
    width: 60px;
    z-index: -1;
    top: 0px;
    border-radius: 30px;
    box-shadow: -35px -35px 0 0 #f4f4f4;
    right: -60px;
    content: "";
  }

}

@media  screen and (max-width:650px) {
    .skeleton-box {
        display: inline-block;
        height: 30vh;
        position: relative;
        overflow: hidden;
      }
      .card-img-top{
        height: 25vh;
        width: 100%;
      }
}

@media screen and (max-width: 500px) {
  .insight-top-container-new-insight-image {
    /* background: linear-gradient(to bottom, rgba(245, 246, 252, 0), rgba(0, 0, 0, 0.73)); */
    height: 60vw;
    object-fit: cover;
    width: 100%;
    z-index: -1;
    position: relative;
    border-radius: 30px;
  }
  .new-insight-title-arrow {
    font-size: 1.5rem !important;
    margin-left: 10px;
    transition: transform 0.9s ease-in-out;
  }
  .insight-top-container-title {
    width: 60%;
  }
  .insight-top-container-title::after {
    position: absolute;
    background: transparent;
    height: 60px;
    width: 60px;
    z-index: -1;
    top: 0px;
    border-radius: 30px;
    box-shadow: -35px -35px 0 0 #f4f4f4;
    right: -56px;
    content: "";
  }
}

@media screen and (max-width: 470px) {
  .Insight {
    margin: 40px 0px;
    padding: 0px 20px;
    display: flex;
    flex-direction: column;
  }
  .skeleton-box {
    display: inline-block;
    height: 20vh;
    position: relative;
    overflow: hidden;
  }
  .card-img-top{
    height: 25vh;
    width: 100%;
  }
}

@media screen and (max-width: 350px) {
  .insight-top-container-title {
    width: 65%;
  }
  .insight-top-container-title::after {
    position: absolute;
    background: transparent;
    height: 60px;
    width: 60px;
    z-index: -1;
    top: 0px;
    border-radius: 30px;
    box-shadow: -36px -36px 0 0 #f4f4f4;
    right: -52px;
    content: "";
  }
  .new_update_arrow {
    width: 1.5rem !important;
  }
}
