#ContactUs{
  height: 100%;
  width: 100%;
  margin: 0px 0px;
  padding: 30px 0px;
  background-color: white;
}
.ContactUs-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0px 80px;
}
.contactus-left-container {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.contactus-heading {
  width: 100%;
  font-size: 3.3vw;
  font-weight: 600;
  background: -webkit-linear-gradient(
    27deg,
    rgba(34, 86, 195, 1) 0%,
    rgba(251, 0, 20, 1) 45%,
    rgba(253, 187, 45, 1) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: flex;
  justify-content: flex-start;
}
.contactus-form-container {
  display: flex;
  width: 100%;
}
.contactus-form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.input-container {
  height: 100%;
  width: 100%;
  padding: 0px 20px;
  border-radius: 30px;
  margin: 10px 0px;
  background: rgb(234, 234, 234);
  display: flex;
  align-items: center;
}

.input-icons {
  color: rgb(93, 93, 93);
}

.contactus-input-fields {
  background: none;
  border: none;
  padding: 20px;
  width: 100%;
  margin: 10px 0px;
  background: rgb(234, 234, 234);
  border-radius: 30px;
  -webkit-appearance: none;
  -moz-appearance: textfield;
  appearance: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.contactus-input-fields-number {
  background: none !important;
  border: none;
  padding: 20px;
  width: 100%;
  margin: 10px 0px;
  background: rgb(234, 234, 234);
  border-radius: 30px;
}

.contactus-input-fields:focus {
  outline: none;
}

.contactus-right-container {
  width: 50%;
  height: 100%;
  user-select: none;
}

.contactus-right-image {
  height: 100%;
  width: 100%;
  user-select: none;
}

.contactus-button {
  width: 25%;
  padding: 15px;
  border-radius: 30px;
  border: 1px solid black;  border: 1px solid black;
}
.contactus-button:hover {
  background: -webkit-linear-gradient(
    27deg,
    rgba(34, 86, 195, 1) 0%,
    rgba(251, 0, 20, 1) 45%,
    rgba(253, 187, 45, 1) 100%
  );
  color: white;
  border: none;
}

.show-alert {
  position: fixed;
  top: 5vh;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
}
.dont-show-alert {
  display: none;
}

/* handline phone input fields */
.PhoneInput {
  display: flex;
  flex-direction: row;
}
.PhoneInputCountry {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}
.PhoneInputCountry select {
  width: 70px;
  background: none;
  border: none;
}
.PhoneInput input {
  background: none;
  border: none;
  width: 100%;
  margin: 10px 0px;
  background: rgb(234, 234, 234);
  border-radius: 30px;
  -webkit-appearance: none;
  -moz-appearance: textfield;
  appearance: none;
}
.PhoneInput input:focus {
  outline: none;
}
.flag-dropdown {
  background: none !important;
  border: none !important;
}
.flag-dropdown:hover {
  background: none !important;
}
.react-tel-input .flag-dropdown.open .selected-flag {
  background: none !important;
  border-radius: 3px 0 0 0;
}
.form-control {
  background: none !important;
  border: none !important;
  width: 100% !important;
}
.form-control:focus {
  outline: none !important;
  border: none !important;
}

@media screen and (max-width: 1260px) {
  .contactus-button {
    width: 30%;
  }
}
@media screen and (max-width: 1060px) {
  .contactus-button {
    width: 40%;
  }
}

@media screen and (max-width: 1000px) {
  .ContactUs-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    padding: 0px 80px;
  }
  .contactus-right-container {
    width: 100%;
    display: none;
  }
  .contactus-left-container {
    width: 100%;
  }
  .contactus-heading {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 850px) {
  .ContactUs-container {
    padding: 0px 50px;
  }
}

@media screen and (max-width: 470px) {
  .contactus-heading {
    font-size: 1.7rem;
  }
  .ContactUs-container {
    padding: 0px 20px;
  }
  .contactus-button{
    font-size: 16px;
    width: 50%;
  }
}
