*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* overflow: auto; */
}
.menu-nav {
    height: 100vh;
    background-color: rgb(41, 41, 41);
    position: fixed;
    right: 0;
    top: 0;
    color: white;
    z-index: 90;
    display: block;
}
.menu-nav-changed{
  height: 100vh;
  background-color: rgb(41, 41, 41);
  position: fixed;
  right: 0;
  top: 0;
  color: white;
  z-index: 90;
  display: none;
}
.menu-body {
  box-sizing: border-box;
  height: 100%;
  padding: 30px 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: rgb(41, 41, 41);
  z-index: 90;
  /* position: fixed; */
}

.nav {
  display: flex;
  flex-direction: column;
  font-size: 50px;
  gap: 12px;
  margin-top: 30px;
  background: none;
  margin-bottom: 20px;
}
.nav p{
    background: none;
}
.header {
  color: rgb(153, 153, 153);
  border-bottom: 1px solid rgb(153, 153, 153);
  text-transform: uppercase;
  font-size: 11px;
  margin-bottom: 20px;
  background: none;
}

a {
  text-decoration: none;
  color: white;
  font-weight: 300;
  background: none;
}

@media screen and (max-width:2000px) {
  .menu-nav {

    display: none;
}
.menu-nav-changed{
  background-color: red;
  display: block;
}
}

@media screen and (max-width:1100px) {
    .nav {
        margin-top: 25px;
    }
}

@media screen and (max-width:425px) {
    .nav {
        font-size: 40px;
    }
    .menu-body{
        width: 90vw;
    }
}