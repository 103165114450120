.SolutionTrack {
  height: 265vh;
  width: 100%;
  margin: 0px 0px;
  padding: 20px 0px;
}
.SolutionTrack-alternative {
  display: none;
}
.inside-solution-track-container {
  height: 100%;
  width: 100%;
  padding: 0px 80px;
  display: flex;
  position: relative;
}

/* left solution track */
.solution-track-left-container {
  width: 55%;
}
.inside-solution-track-left-container {
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.solutions-container {
  display: flex;
  flex-direction: column;
  margin: 20px 0px;
}

.solutions-heading {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 10px;
}
.solution-card-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  gap: 70px;
  height: 100%;
}
.solution-card {
  padding: 20px;
  flex: 1;
  height: 100%;
  width: 100%;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: white;
  /* box-shadow: rgba(50, 50, 93, 0.15) 0px 50px 50px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.25) 0px -2px 6px 0px inset; */
  border-radius: 10px;
  cursor: pointer;
  overflow: hidden;
}
.solution-card-9 {
  padding: 20px;
  height: 100%;
  width: 50%;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background: white;
  /* box-shadow: rgba(50, 50, 93, 0.15) 0px 50px 50px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.25) 0px -2px 6px 0px inset; */
  border-radius: 10px;
  cursor: pointer;
  overflow: hidden;
}
.solution-card-mask {
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: all 0.25s ease-out;
  height: auto;
  width: 100%;

  cursor: default;
  top: -20px;
  left: -20px;
  mask-image: url("../../images/mask.svg");
  object-fit: contain;
  mask-repeat: no-repeat;
  -webkit-mask-size: 40px;
  mask-size: 40px;
  position: absolute;
  color: black;
  font-weight: 600;
}
.inner-solution-card {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.solution-card:hover {
  color: white !important;
}
.solution-card-9:hover {
  color: white !important;
}
.solution-card:hover .solution-card-mask {
  transform: scale(2);
  mask-size: 280px;
  -webkit-mask-size: 280px;
  color: white;
}
.solution-card-9:hover .solution-card-mask {
  transform: scale(2);
  mask-size: 280px;
  -webkit-mask-size: 280px;
  color: white;
}

.solution-card-additonal-info {
  margin-top: 10px;
}
.solution-card-heading {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 5px;
}
.solution-card-sub-heading {
  margin-bottom: 30px;
}

/* right solution track */
.solution-track-right-container {
  width: 45%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  /* position: absolute; */
  /* right: 0px !important; */
  /* inset: 0px auto auto 50vw !important; */
  font-size: 3.3vw;
  font-weight: 600;
  background: -webkit-linear-gradient(
    27deg,
    rgba(34, 86, 195, 1) 0%,
    rgba(251, 0, 20, 1) 45%,
    rgba(253, 187, 45, 1) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-bottom: 0px !important;
}



@media screen and (min-height: 500px) {
  .SolutionTrack {
    /* height: 153vh; */
    width: 100%;
    margin: 0px 0px;
    padding: 20px 0px;
    overflow: hidden;
  }
  .SolutionTrack {
    height: 100%;
    width: 100%;
  }
  .inside-solution-track-container{
    display: grid;
    grid-template-columns: auto auto;
  }
  .solution-track-left-container{
    width: 100%;
    height: 100%;
  }
  .solution-track-right-container{
    width: 100%;
    height: 100%;
  }
}

/* @media screen and (min-height: 850px) {
  .SolutionTrack {
    height: 200vh;
    width: 100%;
    margin: 0px 0px;
    padding: 20px 0px;
    overflow: hidden;
  }
}

@media screen and (min-height: 1200px) {
  .SolutionTrack {
    height: 83vh;
    width: 100%;
    margin: 0px 0px;
    padding: 20px 0px;
    overflow: hidden;
  }
} */

@media screen and (min-width: 1400px) {
  .SolutionTrack {
    height: 100%;
    width: 100%;
  }
  .inside-solution-track-container{
    display: grid;
    grid-template-columns: auto auto;
  }
  .solution-track-left-container{
    width: 100%;
    height: 100%;
  }
  .solution-track-right-container{
    width: 100%;
    height: 100%;
  }
}
@media screen and (min-width: 1430px) {
  .SolutionTrack {
    height: 100%;
  }
  .inside-solution-track-container{
    display: grid;
    grid-template-columns: auto auto;
  }
  .solution-track-left-container{
    width: 100%;
    height: 100%;
  }
  .solution-track-right-container{
    width: 100%;
    height: 100%;
  }
}

@media screen and (min-width: 1500px) {
  .SolutionTrack {
    height: 100%;
    overflow: hidden;
  }
  .inside-solution-track-container{
    justify-content: space-between;
    flex-direction: row;
  }
  .solution-card-container{
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .solution-card {
    height: 300px;
    max-width: 300px;
  }
  .solution-card-9 {
    height: 300px;
    max-width: 300px;
  }
  .solution-card:hover .solution-card-mask {
    transform: scale(4);
    mask-size: 400px;
    -webkit-mask-size: 400px;
    color: white;
  }
  .solution-card-9:hover .solution-card-mask {
    transform: scale(4);
    mask-size: 400px;
    -webkit-mask-size: 400px;
    color: white;
  }
  .solution-card-text {
    font-size: 15px;
  }
  .solution-track-left-container{
    width: 100%;
  }
  .solution-track-right-container{
    width: 100%;
    height: 100%;
  }
}

/* @media screen and (min-width: 1500px) and (max-height: 900px) {
  .SolutionTrack{
    height: 255vh;
  }
}

@media screen and (min-width: 1500px) and (max-height: 780px) {
  .SolutionTrack{
    height: 305vh;
  }
} */


@media screen and (min-width: 2000px) {
  .SolutionTrack {
    height: 100%;
    overflow: hidden;
  }
  .solution-track-right-container {
    font-size: 2.8vw;
    text-align: right;
  }
  .solutions-heading {
    font-size: 34px;
  }
  .solution-card {
    height: 350px;
    max-width: 14vw;
  }
  .solution-card-9 {
    height: 350px;
    max-width: 14vw;
  }
  .solution-card:hover .solution-card-mask {
    transform: scale(3);
    mask-size: 400px;
    -webkit-mask-size: 400px;
    color: white;
  }
  .solution-card-9:hover .solution-card-mask {
    transform: scale(3);
    mask-size: 400px;
    -webkit-mask-size: 400px;
    color: white;
  }
  .solution-card-heading {
    font-size: 28px;
    font-weight: 600;
  }
  .solution-card-sub-heading {
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: 500;
  }
  .solution-card-text {
    font-size: 22px;
  }
  .solution-card-additonal-info {
    font-size: 20px;
  }
}

@media screen and (min-width: 2300px) {
  .SolutionTrack {
    height: 100%;
    overflow: hidden;
  }
}

/* @media screen and (max-width:1450px) {
  .SolutionTrack {
    height: 225vh;
    width: 100%;
    margin: 50px 0px;
    padding: 20px 0px;
    overflow: hidden;
  }
} */

@media screen and (min-width:1300px) and (max-width:1600px) {
  .solution-card-9 {
    background: #fff;
    width: 45%;
}
}

@media screen and (max-width:1400px) {
  .SolutionTrack {
    height: 100%;
    width: 100%;
    margin: 50px 0px;
    padding: 20px 0px;
    overflow: hidden;
  }
  .SolutionTrack {
    height: 100%;
    width: 100%;
  }
  .inside-solution-track-container{
    flex-direction: row;
    justify-content: space-between;
  }
  .solution-track-left-container{
    width: 100%;
    height: 100%;
  }
  .solution-track-right-container{
    width: 100%;
    height: 100%;
  }
}


@media screen and (max-width:1350px) {
  .SolutionTrack {
    width: 100%;
    margin: 50px 0px;
    padding: 20px 0px;
    overflow: hidden;
  }
  .SolutionTrack {
    height: 100%;
    width: 100%;
  }
  .inside-solution-track-container{
    flex-direction: row;
    justify-content: space-between;
  }
  .solution-track-left-container{
    width: 100%;
    height: 100%;
  }
  .solution-track-right-container{
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 1300px) {
  .SolutionTrack {
    width: 100%;
    margin: 50px 0px;
    padding: 20px 0px;
    overflow: hidden;
  }
  .SolutionTrack {
    height: 100%;
    width: 100%;
  }
  .inside-solution-track-container{
    flex-direction: row;
    justify-content: space-between;
  }
  .solution-track-left-container{
    width: 100%;
    height: 100%;
  }
  .solution-track-right-container{
    width: 100%;
    height: 100%;
  }
  .solution-card-container {
    gap: 20px;
  }
}

@media screen and (max-width: 1199px) {
  .SolutionTrack {
    width: 100%;
    margin: 0px 0px;
    padding: 20px 0px;
    overflow: hidden;
  }
  .SolutionTrack {
    height: 100%;
    width: 100%;
  }
  .inside-solution-track-container{
    flex-direction: row;
    justify-content: space-between;
  }
  .solution-track-left-container{
    width: 100%;
    height: 100%;
  }
  .solution-track-right-container{
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 1100px) {
  .SolutionTrack {
    width: 100%;
    margin: 0px 0px;
    padding: 20px 0px;
    overflow: hidden;
  }
  .SolutionTrack {
    height: 100%;
    width: 100%;
  }
  .inside-solution-track-container{
    flex-direction: row;
    justify-content: space-between;
  }
  .solution-track-left-container{
    width: 100%;
    height: 100%;
  }
  .solution-track-right-container{
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 1030px) {
  .SolutionTrack {
    height: 100%;
    width: 100%;
    margin: 0px 0px;
    padding: 20px 0px;
    overflow: hidden;
    display: none;
  }
  .SolutionTrack-alternative {
    height: 100%;
    width: 100%;
    margin: 0px 0px;
    padding: 45px 0px;
    overflow: hidden;
    display: block;
    /* height: 216vh; */
  }
  .inside-solution-track-container {
    display: flex;
    flex-direction: column-reverse;
  }
  .solution-track-left-container {
    width: 100%;
  }
  .solution-track-right-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .service-left-container-heading {
    text-align: left;
  }
  .solution-card {
    height: 250px;
  }
  .solution-card-9 {
    height: 250px;
  }
  .solution-card:hover .solution-card-mask {
    transform: scale(2);
    mask-size: 320px;
    -webkit-mask-size: 320px;
    color: white;
  }
  .solution-card-9:hover .solution-card-mask {
    transform: scale(2);
    mask-size: 320px;
    -webkit-mask-size: 320px;
    color: white;
  }
}

@media screen and (max-width: 850px) {
  .SolutionTrack-alternative {
    height: 100%;
  }
  .inside-solution-track-container {
    padding: 0px 50px;
  }
  .solution-track-right-container {
    font-size: 1.8rem;
  }
  .solution-card:hover .solution-card-mask {
    transform: scale(2);
    mask-size: 300px;
    -webkit-mask-size: 300px;
    color: white;
  }
  .solution-card-9:hover .solution-card-mask {
    transform: scale(2);
    mask-size: 300px;
    -webkit-mask-size: 300px;
    color: white;
  }
}

@media screen and (max-width: 668px) {
  .solution-card {
    height: 300px;
  }
  .solution-card-9 {
    height: 300px;
  }
}

@media screen and (max-width: 580px) {
  .solution-card {
    height: 250px;
    flex: none;
  }
  .solution-card-9 {
    height: 240px;
    flex: none;
    width: 100%;
  }
  .solution-card-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    gap: 10px;
  }
  .solution-card:hover .solution-card-mask {
    transform: scale(3.9);
    mask-size: 300px;
    -webkit-mask-size: 300px;
    color: white;
  }
  .solution-card-9:hover .solution-card-mask {
    transform: scale(3.9);
    mask-size: 300px;
    -webkit-mask-size: 300px;
    color: white;
  }
}

@media screen and (max-width: 470px) {
  .inside-solution-track-container {
    padding: 0px 20px;
  }
  .solution-track-right-container {
    font-size: 1.7rem;
  }
  .SolutionTrack-alternative {
    height: 100%;
  }
}
@media screen and (max-width:360px) {
  .solution-card{
    height: 100%;
    width: 100%;
  }
  .solution-card-9{
    height: 100%;
    width: 100%;
  }
}

@media screen and (max-width: 350px) {
  .solution-track-right-container {
    text-align: left;
  }

}
