.footer{
    display: flex;
    width: 100%;
    justify-content: space-around;
    font-size: 12px;
    gap: 40px;
    background: none;
    position: relative;
    left: 0px;
    align-items: center;
}