.link {
  position: relative;
  display: flex;
  align-items: center;
  background: none;
}

.indicator {
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  left: -30px;
}

.unboring_text{
background: -webkit-linear-gradient(27deg, rgba(34,86,195,1) 0%, rgba(251,0,20,1) 45%, rgba(253,187,45,1) 100%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}

.segment-text{
text-decoration:"none";
 color:"white";
}

.unboring_nav_image{
height: 100px;
}

@media screen and (max-width:420px) {
.unboring_text{
  font-size: 20px;
  background: -webkit-linear-gradient(27deg, rgba(34,86,195,1) 0%, rgba(251,0,20,1) 45%, rgba(253,187,45,1) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.segment-text{
  font-size: 33px;
}
.unboring_nav_image{
  height: 70px;
}
}