*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.Home{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    scroll-behavior: smooth;
    margin: 0;
    padding: 0;
    /* overflow: hidden; */
    /* user-select: none; */
}

.sticky-contactus-button-large{
    color: black;
    position: fixed;
    top: 50vh;
    right: -55px;
    height: 80px;
    z-index: 800;
    background: white;
    /* border: 1px solid black; */
    font-weight: 500;
    font-size: 20px;
    padding: 20px;
    transform: rotate(-90deg);
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
    box-shadow: rgba(14, 30, 37, 0.02) 0px 2px 2px 0px, rgba(14, 30, 37, 0.20) 0px 2px 12px 0px;
}

.sticky-contactus-button-large:hover{
    right: -45px;
}
.sticky-contactus-button-small{
    display: none;
    color: black;
    position: fixed;
    bottom: 40px;
    right: 6vh;
    z-index: 800;
    background: white;
    border-radius: 50%;
    /* border: 1px solid black; */
    font-weight: 500;
    font-size: 20px;
    padding: 20px;
    transform: rotate(-90deg);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.whatsapp-sticky-button{
    position: fixed;
    z-index: 800;
    bottom: 60px;
    right: 20px;
    height: 50px;
    width: 50px;
    cursor: pointer;
}
.floating-message{
    display: none;
}
.whatsapp-sticky-button:hover .floating-message{
    display: block;
    position: absolute;
    width: max-content;
    padding: 10px;
    background: white;
    border-radius: 10px;
    left: -120px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    cursor: pointer;
}

.whatsapp-sticky-button a{
    height: 100%;
    width: 100%;
    cursor: pointer;
}
.whatsapp-sticky-img{
    height: 100%;
    width: 100%;
    cursor:pointer;
}


@media screen and (max-width:500px) {
    .sticky-contactus-button-large{
        display: none;
    }
    .sticky-contactus-button-small{
        display: block ;
    }
    .whatsapp-sticky-button{
        bottom: 140px;
        right: 6vh;
    }
}