.post_image_alternate{
    display: grid;
    height: 100%;
    grid-template-columns: auto auto;
    gap: 20px;
    /* display: flex;
    flex-direction: row;
    height: 100%;
    gap: 20px; */
    margin: 20px 0px;
}

.post_image_img{
    height: 100%;
    width: 100%;
    object-fit: contain;
}
.post_image_img-alt{
    order: 1;
}

.post-description-alternate{
    display: flex;
    /* align-items: center; */
    justify-content: center;
    flex-direction: column;
}

@media screen and (max-width:1070px) {
    .post_image_alternate{
        grid-template-columns: none;
        grid-template-rows: auto auto;
    }
    .post_image_img-alt{
        order:unset;
    }
}