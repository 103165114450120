#Work{
  width: 100%;
  height: 80vh;
  margin: 50px 0px;
  padding: 0px 80px;
}
.inside-work-container1 {
  height: 90%;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  /* overflow: hidden; */
}
.work-container-title{
  font-size: 3.3vw;
  font-weight: 600;
  background: -webkit-linear-gradient(27deg, rgba(34,86,195,1) 0%, rgba(251,0,20,1) 45%, rgba(253,187,45,1) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* margin-bottom: 30px; */
  height: 15%;
  text-align: right;
}

.inside-work-container2{
  display: none;
}

@media screen and (max-width: 1500px) {
  #Work{
    width: 100%;
    height: 100vh;
    margin: 50px 0px;
    padding: 0px 80px;
  }
}
@media screen and (min-width: 1400px) {
  #Work{
    width: 100%;
    height: 70vh;
    margin: 50px 0px;
    padding: 0px 80px;
  }
  .inside-work-container1 {
    height: 85%;
  }
  .work-container-title{
    height: 10%;
  }
}
@media screen and (min-width: 1500px) {
  #Work{
    width: 100%;
    height: 80vh;
    margin: 50px 0px;
    padding: 0px 80px;
  }
}
@media screen and (min-width: 2000px) {
  #Work{
    width: 100%;
    height: 70vh;
    margin: 50px 0px;
    padding: 0px 80px;
  }
  .work-container-title{
    font-size: 2.8vw;
  
  }
}

@media screen and (max-width: 1400px) {
  #Work{
    width: 100%;
    height: 80vh;
    margin: 50px 0px;
    padding: 0px 80px;
  }
  .work-container-title{
    height: 12%;
  }
}
@media screen and (max-width: 1300px) {
  #Work{
    width: 100%;
    height: 70vh;
    margin: 50px 0px;
    padding: 0px 80px;
  }
}

@media screen and (max-width: 1250px) {
  #Work{
    width: 100%;
    height: 55vh;
    margin: 50px 0px;
    padding: 0px 80px;
  }
}

@media screen and (max-width: 1100px) {
  #Work{
    width: 100%;
    height: 55vh;
    margin: 50px 0px;
    padding: 0px 80px;
  }
}

@media screen and (max-width: 1050px) {
  #Work{
    width: 100%;
    height: 50vh;
    margin: 50px 0px;
    padding: 0px 80px;
  }
}

@media screen and (max-width: 900px) {
  .inside-work-container1 {
    gap: 10px;
    display: none;
  }
    #Work{
    width: 100%;
    height: 70vh;
    margin: 50px 0px;
    padding: 0px 80px;
  }
  .inside-work-container2{
    height: 90%;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    gap: 20px;
  }
}

@media screen and (max-width: 850px) {
  #Work{
    padding: 0px 50px;
    height:95vh;
  }
  .work-container-title{
    text-align: left;
    font-size: 1.8rem;
    height: 10%;
  }
}
/* @media screen and (max-width: 700px) {
  #Work{
    height: 60vh;
  }
} */

@media screen and (max-width: 500px) {
  #Work{
    height: 80vh;
  }
}

@media screen and (max-width: 470px) {

  #Work{
    height: 60vh;
    padding: 0px 20px;
  }
  .work-container-title{
    font-size: 1.7rem;
  }
}
@media screen and (max-width: 420px) {
  #Work{
    height: 60vh;
  }
  .work-container-title{
    font-size: 1.7rem;
  }
}

@media screen and (max-width: 400px) {
  #Work{
    height: 60vh;
  }
  .work-container-title{
    font-size: 1.7rem;
  }
}
@media screen and (max-width: 380px) {
  #Work{
    height: 70vh;
  }
  .work-container-title{
    font-size: 1.7rem;
  }
}

@media screen and (max-width: 370px) {
  #Work{
    height: 70vh;
  }
  .work-container-title{
    font-size: 1.7rem;
  }
}

@media screen and (max-width: 350px) {
  #Work{
    height: 60vh;
  }
  .work-container-title{
    font-size: 1.7rem;
  }
}

